import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '../views/Layout.vue'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children:[
      {
        path:"/",
        name:"distribute",
        component:()=> import("@/views/distribute")
      },
      {
        path:"/home",
        name:"home",
        component:()=> import("@/views/home/index")
      },
      {
        path:"/homePE",
        name:"homePE",
        component:()=> import("@/views/phone/homePE/index")
      },
      {
        path:"/juvenile",
        name:"juvenile",
        component:()=> import("@/views/juvenile/index")
      },
      {
        path:"/juvenilePE",
        name:"juvenilePE",
        component:()=> import("@/views/phone/juvenilePE/index")
      },
      {
        path:"/hsk",
        name:"hsk",
        component:()=> import("@/views/hsk/index")
      },
      {
        path:"/hskPE",
        name:"hskPE",
        component:()=> import("@/views/phone/hskPE/index")
      },
      {
        path:"/olevel",
        name:"olevel",
        component:()=> import("@/views/olevel/index")
      },
      {
        path:"/olevelPE",
        name:"olevelPE",
        component:()=> import("@/views/phone/olevelPE/index")
      },
      {
        path:"/advantage",
        name:"advantage",
        component:()=> import("@/views/advantage/index")
      },
      {
        path:"/advantagePE",
        name:"advantagePE",
        component:()=> import("@/views/phone/advantagePE/index")
      },
      {
        path:"/teaching",
        name:"teaching",
        component:()=> import("@/views/teaching/index")
      },
      {
        path:"/teachingPE",
        name:"teachingPE",
        component:()=> import("@/views/phone/teachingPE/index")
      },
      {
        path:"/further",
        name:"further",
        component:()=> import("@/views/further/index")
      },
      {
        path:"/furtherPE",
        name:"furtherPE",
        component:()=> import("@/views/phone/furtherPE/index")
      },
      {
        path:"/freetrial",
        name:"freetrial",
        component:()=> import("@/views/freetrial/index")
      },
      {
        path:"/freetrialPE",
        name:"freetrialPE",
        component:()=> import("@/views/phone/freetrialPE/index")
      },
      {
        path:"/pay",
        name:"pay",
        component:()=> import("@/views/pay")
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.afterEach((to, from, next) => {
  
console.log("next", from)
  window.scrollTo(0, 0)
})
export default router
