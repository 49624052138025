import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import './common/css/base.less';
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import i18n from '@/language/'
import 'amfe-flexible'
import './utils/mobile-util'
import {myMixin} from "@/mixin/gotoFree";
import { Popup,Swipe, SwipeItem  } from 'vant';
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mixin(myMixin);
app.use(ElementPlus);
app.use(i18n);
app.use(store);
app.use(router);
app.use(Popup);
app.use(SwipeItem);
app.use(Swipe);


app.mount('#app');
