<template>
  <header class="header_box">
    <!-- logo -->
    <img class="header_logo" @click="erJIClick" src="../assets/logo.png" alt="" />
    <!-- 导航栏 begin -->
    <nav
      :class="
        $t('isLang') == 2 ? 'header_en_navigation header_navigation' : 'header_navigation'
      "
    >
      <ul>
        <li @click="showClick2">
          <router-link active-class="click_link" :to="erjilu">
            <span>{{ $t("header.li1") }}</span>
            <div class="hover_box">
              <div class="hover_box_1">
                <p @click="erJIClick(1)">{{ $t("header.li7") }}</p>
                <p @click="erJIClick(2)">{{ $t("header.li8") }}</p>
                <!-- <p @click="erJIClick(3)">AP, IB, O-level</p> -->
              </div>
            </div>
          </router-link>
        </li>
        <li>
          <router-link active-class="click_link" to="/olevel">
            <span>{{ $t("header.li2") }}</span>
            <div class="hover_box hover_box1">
              <div class="hover_box_1">
                <p @click="erJIClick(3)">AP, IB, O-level</p>
              </div>
            </div>
          </router-link>
        </li>
        <li>
          <router-link active-class="click_link" to="/advantage"
            ><span>{{ $t("header.li3") }}</span></router-link
          >
        </li>
        <li>
          <router-link active-class="click_link" to="/teaching"
            ><span>{{ $t("header.li4") }}</span></router-link
          >
        </li>
        <li>
          <router-link active-class="click_link" to="/further"
            ><span>{{ $t("header.li5") }}</span></router-link
          >
        </li>
        <li>
          <router-link active-class="click_link" to="/freetrial">
            <span> {{ $t("header.li6") }} </span>
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- 导航栏 end -->

    <nav class="header_taget">
      <!-- 语言转换 begin -->
      <div class="header_langauge" @click="showClick">
        <img src="../assets/img/wa.png" alt="" />
        <el-dropdown ref="dropdown1" trigger="contextmenu">
          <span class="el-dropdown-link">
            {{ lang == 1 ? "中\xa0\xa0文" : "English" }}
          </span>
          <template #dropdown>
            <el-radio-group v-model="lang" @change="updateLang">
              <el-dropdown-menu>
                <el-dropdown-item
                  ><el-radio :label="1">
                    中&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;文
                  </el-radio></el-dropdown-item
                >
                <el-dropdown-item
                  ><el-radio :label="2"> English </el-radio></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-radio-group>
          </template>
        </el-dropdown>
        <i class="iconfont icon-xiala"></i>
      </div>
      <!-- 语言转换 end -->
      <!-- 跳转 学员中心 begin -->
      <button class="header_button" @click="goToXueYuan">
        <span :class="$t('isLang') == 2 ? 'enSpan' : ''">{{ $t("header.button") }}</span>
        <i
          :class="$t('isLang') == 2 ? 'enI' : ''"
          class="iconfont icon-jiantou_xiangyou"
        ></i>
      </button>
      <!-- 跳转 学员中心 end -->
    </nav>
  </header>
</template>

<script>
import { toRefs, reactive, onMounted, inject, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default {
  name: "HeaderView",
  setup() {
    // 路由
    const router = useRouter();
    // 调用顶层 vueapp 方法 刷新路由
    const reload = inject("reload");
    const toggle = inject("toggle");
    // i18n 语言切换
    const { locale } = useI18n();
    // vuex 数据中心
    const store = useStore();
    // 本地数据中心
    const obj = reactive({
      lang: 1,
      erjilu: "/juvenile",
      xzho: "1",
    });
    // 语言切换下拉框
    const dropdown2 = ref();
    const showClick2 = () => {
      if (!dropdown2.value) return;
      {
        dropdown2.value.handleOpen();
      }
    };
    // 语言切换下拉框
    const dropdown1 = ref();
    const showClick = () => {
      if (!dropdown1.value) return;
      {
        dropdown1.value.handleOpen();
      }
    };
    // 语言切换 事件
    const updateLang = (e) => {
      switch (e) {
        case 1:
          localStorage.setItem("language", "zh");
          locale.value = "zh";
          obj.lang = 1;
          break;
        case 2:
          localStorage.setItem("language", "en");
          locale.value = "en";
          obj.lang = 2;
          break;
      }
      toggle();
      reload();
    };
    const erJIClick = (index) => {
      switch (index) {
        case 1:
          router.push({
            path: "/juvenile",
          });
          obj.erjilu = "/juvenile";
          obj.xzho = "1";
          break;
        case 2:
          router.push({
            path: "/hsk",
          });
          obj.erjilu = "/hsk";
          obj.xzho = "2";
          break;
        case 3:
          router.push({
            path: "/olevel",
          });
          obj.xzho = "3";
          break;
        default:
          router.push({
            path: "/",
          });
          break;
      }
    };
    // 初始化生命周期
    onMounted(() => {
      obj.lang = localStorage.getItem("language") == "en" ? 2 : 1;
      let urlLang = window.location.href.split("lang=")[1];
      if (
        (urlLang != undefined) &
        (urlLang != null) &
        (urlLang != "undefined") &
        (urlLang != "")
      ) {
        console.log(urlLang);
        const { locale } = useI18n();
        locale.value = urlLang;
      } else {
        locale.value = obj.lang == 2 ? "en" : "zh";
      }
    });
    const goToXueYuan = () => {
      let url = window.location.href;
      if (url.indexOf("zhixingchinese") != -1) {
        window.location.href = "https://student.zhixingchinese.com/";
      } else {
        window.location.href = "https://student.zhixingmandarin.com/";
      }
    };
    return {
      ...toRefs(obj),
      dropdown1,
      showClick,
      dropdown2,
      showClick2,
      updateLang,
      erJIClick,
      goToXueYuan,
    };
  },
};
</script>
<style lang="less" scoped>
/deep/.el-dropdown-menu {
  border-radius: 20px;
}
.xzho {
  color: rgba(88, 130, 237, 1);
}
/deep/.el-dropdown-menu__item {
  text-align: center !important;
  display: block;
  width: 50px !important;
  height: 29px !important;
  padding: 0 17px 0 10px;
  .el-radio__label {
    font-size: 10px !important;
  }
  .el-radio__original {
    width: 12px;
    height: 12px;
  }
}
.header_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(88, 130, 237, 1);
  color: #fff;
  position: relative;
  .header_taget {
    position: absolute;
    top: 0;
    right: 75px;
    box-sizing: border-box;
    padding-top: 15px;
    display: flex;
  }
  // 跳转 学员中心 begin
  .header_button {
    cursor: pointer;
    min-width: 80px;
    height: 20px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    border-radius: 18px;
    text-align: center;
    margin-left: 14px;
    position: relative;
    span {
      position: absolute;
      left: 11px;
      top: 50%;
      margin-top: -7px;
      font-size: 10px;
      font-weight: 400;
      color: rgba(88, 130, 237, 1);
    }
    .enSpan {
      font-size: 10px;
      font-weight: 300;
      left: 3px;
      letter-spacing: -0.005rem !important;
    }
    i {
      position: absolute;
      right: 11px;
      top: 50%;
      font-size: 15px;
      margin-top: -7px;
      color: rgba(88, 130, 237, 1);
    }
    .enI {
      right: 5px;
      top: 50%;
      position: absolute;
    }
  }
  // 跳转 学员中心 end

  // 头部语言切换 begin
  .header_langauge {
    cursor: pointer;
    padding: 0 6px 0 10px;
    min-width: 68px;
    height: 18px;
    display: flex;
    border: 1px solid #fff;
    border-radius: 18px;
    vertical-align: middle;
    img {
      width: 16px;
      height: 13px;
      vertical-align: top;
      margin-top: 2px;
      line-height: 18px;
    }
    span {
      font-size: 10px;
      color: #fff;
      vertical-align: top;
      line-height: 18px;
      margin: 0 5px;
    }
    i {
      font-size: 15px;
      vertical-align: top;
    }
  }
  // 头部语言切换 end

  // 头部logo begin
  .header_logo {
    position: absolute;
    left: 75px;
    top: 4.5px;
    width: 42px;
    height: 42px;
    opacity: 1;
    cursor: pointer;
  }
  // 头部logo end
  // 头部导航栏 begin
  .header_navigation {
    position: absolute;
    left: 240px;
    ul {
      display: flex;
      // width: 600px;
      padding: 0;
      margin: 0 auto;
      li:hover .hover_box {
        display: block;
      }
      .hover_box:hover {
        display: block;
      }
      li {
        flex: 1;
        width: 100px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;

        .hover_box {
          box-shadow: 0px 1px 5px rgba(34, 25, 25, 0.2);
          display: none;
          width: 120px;
          height: 63px;
          position: absolute;
          bottom: -63px;
          left: -10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          font-family: "Inter", sans-serif !important;
          .hover_box_1 {
            font-size: 13px;
            font-weight: 100;
            font-family: "Inter", sans-serif !important;
            width: 120px;
            height: 60px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            line-height: 21.95px !important;
            left: 0;
            box-sizing: border-box;
            padding-top: 7px;
            color: #000;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            white-space: nowrap;
            p {
              margin: 0;
            }
            p:hover {
              color: #466ed4;
            }
          }
        }
        .hover_box1 {
          width: 120px;
          height: 38px;
          position: absolute;
          bottom: -38px;
          left: -10px;
          .hover_box_1 {
            font-family: "Inter", sans-serif !important;
            font-weight: 100 !important;
            width: 120px !important;
            height: 35px !important;
            font-size: 13px;
            letter-spacing: -0.003rem !important;
          }
        }
        outline: none;
        .el-dropdown {
          .el-dropdown-link:focus {
            outline: none;
          }
          span {
            outline: none;
          }
        }
        a {
          color: #fff !important;
          font-size: 15px;
          font-weight: 400;
          width: 100%;
          height: 100%;
          color: #fff;
          display: inline-block;
          letter-spacing: 0.005rem;
          padding: 0;
          vertical-align: top;
        }
        span {
          line-height: 50px;
          color: #fff;
          font-size: 15px;
        }
        .click_link {
          background-color: #466ed4;
        }
      }
    }
  }
  .header_en_navigation {
    left: 220px !important;
    ul {
      li:nth-child(1),
      li:nth-child(2) {
        span {
          letter-spacing: -0.008rem !important;
        }
      }
      li {
        .hover_box_1 {
          font-family: "Inter", sans-serif !important;
          letter-spacing: -0.008rem !important;
        }
        .hover_box1 {
          .hover_box_1 {
            font-family: "Inter", sans-serif !important;
          }
        }
        width: 100px !important;
        a {
          font-weight: 300 !important;
          letter-spacing: -0.003rem;
          font-size: 14px;
        }
        span {
          font-weight: 300 !important;
          letter-spacing: -0.003rem;
          font-size: 14px;
        }
      }
    }
  }
  // 头部导航栏 eng
}
</style>
