export const myMixin = {
    methods:{
        gotoFreeTrial(){
          this.$router.push('/freetrial')
        },
        gotoFreeTrialPE(){
          this.$router.push('/freetrialPE')
        },
        gotoPath(str){
          this.$router.push(str)
        }
    }
};