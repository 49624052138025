<template>
  <div class="header_box">
    <div class="caidan" @click="cdClick">
      <i class="iconfont icon-caidan"></i>
      <div class="caidan_box" v-if="isShow">
        <router-link active-class="titleActive" to="/homePE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'首页':'Home Page'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <router-link active-class="titleActive" to="/juvenilePE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'青少年中文课程':'Juvenile Course'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <router-link active-class="titleActive" to="/hskPE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'HSK标准课程':'HSK Course'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <router-link active-class="titleActive" to="/olevelPE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'应试课程':'Examing Courses'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <router-link active-class="titleActive" to="/advantagePE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'知行优势':'Advantage'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <router-link active-class="titleActive" to="/teachingPE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'知行师资':'Faculty'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <router-link active-class="titleActive" to="/furtherPE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'拓展阅读':'Blog'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <router-link active-class="titleActive" to="/freetrialPE">
          <div :class="$t('isLang')=='1'?'title':'title title_en'">
            <span>{{$t('isLang')=='1'?'免费试听':'Free Trail'}}</span>
            <div class="dian"></div>
          </div>
        </router-link>
        <img class="xlcd" src="@/assets/peimg/xlcd.png" alt="" />
        <img class="xicon" src="@/assets/peimg/xicon.png" alt="" />
      </div>
    </div>
    <div class="language_box" tabindex="0"  @blur="langIsShow = false" @click="langIsShow = !langIsShow">
      <div class="top_img"><img src="@/assets/peimg/wa.png" alt="" /></div>
      <div class="title">{{ $t("isLang") == 1 ? "中文" : "English" }}</div>
      <div class="bot_img"><img src="@/assets/peimg/sjx.png" alt="" /></div>
      <div class="language_click"  v-if="langIsShow">
        <div
          @click="langClick(1)"
          :class="$t('isLang') == 1 ? 'radio_xz radio_xz_active' : 'radio_xz '"
        >
          <div class="yuan_box"><div class="yuanxing"></div></div>
          <span>中&nbsp;&nbsp;&nbsp;&nbsp; 文</span>
        </div>
        <div
          @click="langClick(2)"
          :class="$t('isLang') == 1 ? 'radio_xz' : 'radio_xz radio_xz_active'"
        >
          <div class="yuan_box"><div class="yuanxing"></div></div>
          <span>English</span>
        </div>
        <i class="iconfont icon-sanjiaoxing sanjiaoxing_my"></i>
      </div>
    </div>
    <div class="student_box" @click="goToPhone">
      <div :class="$t('isLang') == 1 ? '' : 'button_en'">{{ $t("header.button") }}</div>
      <div><img src="@/assets/peimg/jiantou.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const reload = inject("reload");
    // i18n 语言切换
    const { locale } = useI18n();
    const obj = reactive({
      isShow: false,
      langIsShow: false,
    });
    const cdClick = () => {
      obj.isShow = !obj.isShow;
    };
    const langClick = (e) => {
      switch (e) {
        case 1:
          localStorage.setItem("language", "zh");
          locale.value = "zh";
          break;
        case 2:
          localStorage.setItem("language", "en");
          locale.value = "en";
          break;
      }
      reload();
    };
    const goToPhone = () => { 
      window.location.href = "https://phone.zhixingchinese.com/#/?isPhone=1";
    };
    const blurCLick = () => {
      obj.langIsShow = false;
    };  
    return { cdClick, ...toRefs(obj), langClick,goToPhone,blurCLick };
  },
};
</script>
<style lang="less" scoped>
.header_box {
  position: fixed;
  width: 100%;
  height: 178px;
  background-color: #6991f5;
  box-sizing: border-box;
  padding: 49px 0 0 42px;
  display: flex;
  .student_box {
    position: absolute;
    top: 49px;
    right: 47px;
    min-width: 310px;
    height: 80px;
    background: rgba(255, 255, 255, 1);
    border-radius: 40px;
    display: flex;
    color: rgba(88, 130, 237, 1);
    div:nth-child(1) {
      min-width: 208px;
      height: 80px;
      box-sizing: border-box;
      padding-left: 49px;
      font-size: 40px;
      font-weight: 400;
      box-sizing: border-box;
      padding-top: 15px;
    }
    .button_en {
      font-family: "Inter-Regular";
      letter-spacing: -3px;
      padding-left: 20px !important;
    }
    div:nth-child(2) {
      width: 102px;
      height: 80px;
      position: relative;
      img {
        position: absolute;
        top: 24.29px;
        right: 29.22px;
        width: 48.59px;
        height: 30.92px;
      }
    }
  }
  .language_box {
    position: absolute;
    top: 49px;
    right: 404px;
    min-width: 300px;
    height: 80px;
    opacity: 1;
    display: flex;
    border-radius: 40px;
    border: 1px solid #fff;
    outline: none;
    .language_click {
      position: absolute;
      top: 125px;
      left: -10%;
      width: 120%;
      height: 300px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 4px 10px 30px #bdbdbd;
      .radio_xz {
        width: 62%;
        margin-left: 38%;
        height: 150px;
        text-align: left;
        font-size: 50px;
        box-sizing: border-box;
        padding-top: 40px;
        position: relative;
        .yuan_box {
          position: absolute;
          top: 50%;
          left: -95px;
          margin-top: -25px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid #bdbdbd;
        }
      }
      .radio_xz_active {
        color: rgba(104, 143, 242, 1);
        .yuan_box {
          background-color: rgba(104, 143, 242, 1);
          border: none;
          div {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
      i {
        font-size: 60px;
        transform: rotate(180deg);
        position: absolute;
        top: -46px;
        left: 50%;
        margin-left: -30px;
        color: #fff;
      }
    }
    .title {
      min-width: 145px;
      text-align: center;
      box-sizing: border-box;
      padding: 12px 29px 0 29px;
      font-size: 40px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      white-space:nowrap;
    }
    .top_img {
      width: 85px;
      height: 80px;
      text-align: right;
      position: relative;
      img {
        position: absolute;
        top: 12.05px;
        right: 0;
        width: 48.84px;
        height: 57.21px;
      }
    }
    .bot_img {
      width: 74px;
      height: 80px;
      text-align: right;
      position: relative;
      img {
        position: absolute;
        top: 25px;
        left: 0;
        width: 47px;
        height: 30px;
      }
    }
  }
  .caidan {
    width: 300px;
    height: 73px;
    position: relative;
    .caidan_box {
      position: fixed;
      top: 178px;
      left: 40px;
      width: 480px;
      background-color: #fff;
      height: 720px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 9px 46px rgba(0, 0, 0, 0.16);
      backdrop-filter: blur(27.04px);
      box-sizing: border-box;
      padding-top: 35px;
      padding-left: 40px;
      .title {
        width: 407px;
        height: 61px;
        font-size: 50px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 61px;
        color: #898b9b;
        text-align: left;
        margin-bottom: 30px;
        position: relative;
        z-index: 20;
        .dian {
          position: absolute;
          top: 50%;
          right: 0;
          width: 14px;
          height: 14px;
          margin-top: -7px;
          border-radius: 50%;
          background-color: #cccccc;
        }
      }
    .title_en{
      span{
        font-size: 46px;
      white-space:nowrap;
      letter-spacing: -1.2px;
      }
    }
      .titleActive {
        span {
          color: rgba(104, 143, 242, 1);
        }
        .dian {
          background-color: #6991f5;
        }
      }
    }
    i {
      font-size: 95px;
      color: #fff;
    }
    .xicon {
      position: absolute;
      bottom: -100px;
      left: 216px;
      width: 56px;
      height: 48px;
      z-index: 10;
    }
    .xlcd {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
    }
  }
}
</style>
