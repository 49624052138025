<template>
  <Header v-if="i > 1300" class="header_box" />
  <HeaderPE v-if="i < 1300" class="header_box" />
  <div v-if="i < 1300" class="zhanwei"></div>
  <main class="main_box" @mousewheel="scrollChange">
    <router-view></router-view>
  </main>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HeaderPE from "@/components/HeaderPE.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();
const scrollChange = (res) => {
  store.state.offsetY = res.pageY;
};
let i = window.innerWidth;

</script>

<style lang="less" scoped>
.zhanwei {
  width: 100%;
  height: 128px;
}
.header_box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}
.main_box {
  box-sizing: border-box;
  padding-top: 0px;
}
</style>
