import { createStore } from 'vuex'

export default createStore({
  state : ()=>{
      return {
          offsetY:null
      }
  },
  mutations:{
      SET_OFFSETY(state,val){
          state.offsetY = val;
      }
  },
  actions:{
      
  }
})
